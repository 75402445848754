<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            冰山數據Partner
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          欢迎您的加入! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          您正在申请成为冰山數據的合作伙伴，我们将会在有合适的数据采集或标注项目时通过您的注册邮箱与您取得联系，并邀约您正式入库，请随时关注您的邮箱，并确保我们的邮件不会被您的邮箱服务商误判为垃圾邮件。
        </b-card-text>


        <b-card-text class="mb-2">
          同时，我们也推荐您关注我们的<a style="color:#7367f0 !important" @click="toWebsite">官方网站</a>，以及我们的微信公众号，以便及时获取我们的最新动态。
        </b-card-text>

        <div style="overflow:hidden" class="mb-2">
          <img style="height: 100%; width: 100%;" :src="qrCodeSrc">
        </div>

        <b-card-title class="mb-1">
          完善您的信息
        </b-card-title>
        <b-card-text class="mb-2">
          为了让我们了解到贵司的更多信息和优势，请完善以下信息，在您被正式邀约入库前，您可以随时修改这些信息。
        </b-card-text>

        <hr>
          <b-card-text class="d-flex justify-content-center">联系人信息</b-card-text>


          <validation-provider
              #default="validationContext"
              name="姓"
              rules="required"
            >
          <b-form-group
            label="姓"
            label-for="contact-last-name"
          >
            <b-form-input
              :state="getValidationState(validationContext)"
              id="contact-last-name"
              v-model="contactLastName"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="名"
              rules="required"
            >
          <b-form-group
            label="名"
            label-for="contact-first-name"
          >
            <b-form-input
              :state="getValidationState(validationContext)"
              id="contact-first-name"
              v-model="contactFirstName"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="性别"
              rules="required"
            >
          <b-form-group
            label="性别"
            label-for="contact-gender"
          >
          <v-select
                v-model="contactGender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genderOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-role"
              />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="职位"
              rules="required"
            >
          <b-form-group
            label="职位"
            label-for="contact-title"
          >
            <b-form-input
              :state="getValidationState(validationContext)"
              id="contact-first-name"
              v-model="contactTitle"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="电话"
              rules="required|integer"
            >
          <b-form-group
            label="电话"
            label-for="contact-phone"
          >
            <b-form-input
            :state="getValidationState(validationContext)"
              id="contact-phone"
              type="number"
              @wheel.prevent = null
              v-model="contactPhone"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
          <b-form-group
            label="Email"
            label-for="contact-email"
          >
            <b-form-input
            :state="getValidationState(validationContext)"
              id="contact-email"
              v-model="contactEmail"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

          </b-form-group>
          </validation-provider>



        <hr>
        <b-card-text class="d-flex justify-content-center">公司信息</b-card-text>

        <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
        >
        <validation-provider
              #default="validationContext"
              name="公司名称"
              rules="required"
            >
          <b-form-group
            label="公司名称"
            label-for="company-name"
          >
            <b-form-input
              :state="getValidationState(validationContext)"
              id="company-name"
              v-model="companyName"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="注册地址"
              rules="required"
            >
          <b-form-group
            label="注册地址"
            label-for="address"
          >
            <b-form-input
            :state="getValidationState(validationContext)"
              id="address"
              v-model="companyAddress"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="税号"
              rules="required"
            >
          <b-form-group
            label="税号"
            label-for="tax-number"
          >
            <b-form-input
            :state="getValidationState(validationContext)"
              id="tax-number"
              v-model="taxNumber"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="开户银行"
              rules="required"
            >
          <b-form-group
            label="开户银行"
            label-for="opening-bank"
          >
            <b-form-input
            :state="getValidationState(validationContext)"
              id="opening-bank"
              v-model="openingBank"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="银行账号"
              rules="required"
            >
          <b-form-group
            label="银行账号"
            label-for="bank-account"
          >
            <b-form-input
            :state="getValidationState(validationContext)"

              id="opening-bank"
              v-model="bankAccount"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="座机"
              rules="required|integer"
            >
          <b-form-group
            label="座机"
            label-for="telephone"
          >
            <b-form-input
            :state="getValidationState(validationContext)"

              id="telephone"
              v-model="telephone"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="全职标注员数量"
              rules="required"
            >
          <b-form-group
            label="全职标注员数量"
            label-for="labelers"
          >
            <b-form-input
            :state="getValidationState(validationContext)"
              type="number"
              @wheel.prevent = null
              id="labelers"
              v-model="labelers"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <b-form-group
            label="能力标签"
            label-for="abilities"
          >
          <v-select
              multiple
              v-model="abilities"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :options="abilityOptions"
              :reduce="option => option.value"
          />
          </b-form-group>

          <b-form-group
            label="公司介绍文档"
            label-for="introduction"
          >
          <b-form-file
                accept=".pdf"
                v-model="introFile"
                :placeholder="introPlaceholder"
              />
<!--               <a v-if="introFileFsId"
              class="text-primary"
              @click="downloadIntro()"
              :disabled="fileDownloading">
              <font-awesome-icon
                icon="fa-solid fa-paperclip"
              />
              {{ introFileName }}
            </a> -->
          </b-form-group>



          <b-form-group
            label="营业执照"
            label-for="license"
          >
          <b-form-file
                accept=".pdf"
                v-model="licenseFile"
                :placeholder="licensePlaceholder"
              />
<!--               <a v-if="licenseFileFsId"
              class="text-primary"
              @click="downloadLicense()"
              :disabled="licenseDownloading">
              <font-awesome-icon
                icon="fa-solid fa-paperclip"
              />
              {{ licenseFileName }}
            </a> -->
          </b-form-group>

        </validation-observer>

        <b-button
          class="mt-1"
          variant="primary"
          block
          @click="saveInfo"
          :disabled="isSaving"
        >
              保存
          </b-button>

        <div class="divider my-2">
          <div class="divider-text">
          </div>
        </div>

        <b-button
          variant="outline-danger"
          block
          @click="signOut"
        >
              退出登录
          </b-button>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInvalidFeedback,BFormTextarea,BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,BFormFile
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'


export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
    BFormFile,
    BFormInvalidFeedback
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      fileDownloading:false,
      licenseDownloading:false,
      isSaving:false,
      genderOptions:[
        {value:'male',label:'男'},
        {value:'female',label:'女'}
      ],
      abilityOptions:[
        { value:'image',text:'图像标注'},
        { value:'audio',text:'音频标注'},
        { value:'text',text:'文本标注'},
        { value:'pointcloud',text:'点云标注'},
        { value:'collection',text:'数据采集'},
      ],
      qrCodeSrc: require('/public/QRCODE.png'),
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,

      contactLastName: '',
      contactFirstName: '',
      contactGender: '',
      contactTitle: '',
      contactEmail: '',
      contactPhone: '',

      companyName: '',
      companyAddress: '',
      taxNumber: '',
      bankAccount:'',
      openingBank:'',
      telephone:'',

      labelers: '',
      abilities: [],

      introFile: null,
      introFileName: '',
      introFileFsId: '',

      licenseFile: null,
      licenseFileName: '',
      licenseFileFsId: '',
    }
  },
  methods:{
    toWebsite(){
      window.open('https://www.markhk.cn', '_blank')
    },
    signOut(){
      localStorage.removeItem('userData')
      localStorage.removeItem('jwt')
      this.$router.push({ name: 'auth-login-partner' })
    },
    saveInfo(){
      if (!this.introFileFsId && !this.introFile) {
        this.$toast.error('请上传公司介绍文档')
      return
      }
      if (!this.licenseFileFsId && !this.licenseFile)  {
        this.$toast.error('请上传营业执照')
        return
      }
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          this.isSaving = true
          var formData = new FormData()
          if (this.introFile) formData.append('introduction',this.introFile)
          if (!this.introFile) this.introFile = null
          if (this.licenseFile) formData.append('license',this.licenseFile)
          if (!this.licenseFile) this.licenseFile = null
          formData.append('contact_last_name',this.contactLastName)
          formData.append('contact_first_name',this.contactFirstName)
          formData.append('contact_gender',this.contactGender)
          formData.append('contact_title',this.contactTitle)
          formData.append('contact_phone',this.contactPhone)
          formData.append('contact_email',this.contactEmail)

          formData.append('company_name',this.companyName)
          formData.append('company_address',this.companyAddress)
          formData.append('company_tax_number',this.taxNumber)
          formData.append('company_bank_account',this.bankAccount)
          formData.append('company_opening_bank',this.openingBank)
          formData.append('company_telephone',this.telephone)
          
          formData.append('labelers',this.labelers)
          formData.append('abilities',this.abilities)
          this.$axios.post('/partner/companies/update_info',formData).then((response)=>{
            this.isSaving = false
        if (response.data.status == "ok"){
          this.getInfo()
        }
      }
      )
      }})
    },
    getInfo(){
      this.$axios.get('/partner/companies/get_info').then(resp =>{
        var info = resp.data.data.info
        var intro = resp.data.data.intro
        var license = resp.data.data.license
        this.contactLastName = info.contact_last_name
        this.contactFirstName = info.contact_first_name
        this.contactGender = info.contact_gender
        this.contactTitle = info.contact_title
        this.contactPhone = info.contact_phone
        this.contactEmail = info.contact_email

        this.companyName = info.company_name
        this.companyAddress = info.company_address
        this.taxNumber = info.company_tax_number
        this.openingBank = info.company_opening_bank
        this.bankAccount = info.company_bank_account
        this.telephone = info.company_telephone
        this.labelers = info.labelers
        this.abilities = info.abilities
        this.introFileName = intro.intro_file_name
        this.introFileFsId = intro.intro_file_fs_id
        this.licenseFileName = license.license_file_name
        this.licenseFileFsId = license.license_file_fs_id

        this.$refs.refFormObserver.reset()
      })
    },
    downloadIntro(){
      this.fileDownloading = true
      this.$axios.get("/partner/companies/download_file/" + this.introFileFsId).then(res=>{  
        this.fileDownloading = false
        if (res.data.status == "ok"){
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', this.introFileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }      
      })
    },
    downloadLicense(){
      this.licenseDownloading = true
      this.$axios.get("/partner/companies/download_file/" + this.licenseFileFsId).then(res=>{  
        this.licenseDownloading = false
        if (res.data.status == "ok"){
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', this.licenseFileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }      
      })
    }
  },
  created(){
    this.getInfo()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    introPlaceholder(){
      if (this.introFileName) {
        return this.introFileName
      } else {
        return '请选择或拖拽文件至此（仅支持pdf格式）...'
      }
    },
    licensePlaceholder(){
      if (this.licenseFileName) {
        return this.licenseFileName
      } else {
        return '请选择或拖拽文件至此（仅支持pdf格式）...'
      }
    },
  },
  setup() {
    const {getValidationState} = formValidation()
    return {
      getValidationState,
}
},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
